import React, { useState, useMemo, useEffect } from 'react';
import {
  Wrapper,
  DataWrapper,
  DataSection,
  Information,
  Metrics,
  MetricsWrapper,
  MetricItem,
  EditorWrapper,
  EditorsSection,
  ButtonWrapper,
} from './styles';
import { useTranslation } from 'react-i18next';
import {
  InfinityScrollDropdown,
  Input,
  PrimaryButton,
  SecondaryButton,
  SuccessModal,
} from 'common/components';
import Editor from '../lexical';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'common/hooks/redux';
import {
  getConsultationData,
  getSymptoms,
  saveConsultationData,
} from 'applicaiton/store/reducers/Consultation/ActionCreators';
import { useConsultationDataForm } from '../../hooks/useConsultationDataForm';
import { useLocalizeKey } from 'common/hooks/useLocalizeKey';
import { setSymptoms } from 'applicaiton/store/reducers/Consultation/ConsultationSlice';
import {
  CommonEntitiesListResponse,
  SymptomResponseDto,
} from '@docbay/schemas';
import { useLanguage } from 'common/hooks/useLanguage';
import Loader from 'common/components/Loader';

const ConsultationData = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [searchItem, setSearchItem] = useState('');
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { localizeNameKey } = useLocalizeKey();
  const [notes, setNotes] = useState('');
  const [plan, setPlan] = useState('');
  const [successModalOpened, setSuccessModalOpened] = useState(false);
  const dispatch = useAppDispatch();
  const { appointmentId } = useParams();

  const { symptoms, data, symptomsTotalPages, isLoading } = useAppSelector(
    (state) => state.consultationData,
  );

  useEffect(() => {
    dispatch(getConsultationData(appointmentId!));
  }, []);

  const setConsultationData = () => {
    const symptomsIds =
      data?.symptoms?.map((symptom) => String(symptom.id)) || [];

    setValue('symptoms', symptomsIds! as string[], {
      shouldValidate: true,
    });
    setValue('temperature', data?.healthMetrics?.temperature?.value);
    setValue('pulse', data?.healthMetrics?.pulse?.value);
    setValue('bloodGlucose', data?.healthMetrics?.bloodGlucose?.value);
    setValue(
      'bloodPressureDiastolic',
      data?.healthMetrics?.bloodPressureDiastolic?.value,
    );
    setValue(
      'bloodPressureSystolic',
      data?.healthMetrics?.bloodPressureSystolic?.value,
    );
    setValue('weight', data?.healthMetrics?.weight?.value);
    setValue('height', data?.healthMetrics?.height?.value);
    setValue('respiratoryRate', data?.healthMetrics?.respiratoryRate?.value);
    setValue('spO2', data?.healthMetrics?.spO2?.value);
    setValue('reason', data?.reason || '');
    setNotes(data?.notes || '');
    setPlan(data?.treatmentPlan || '');
  };

  useEffect(() => {
    data && setConsultationData();
  }, [data]);

  const symptomsOptions = useMemo(() => {
    const options = symptoms?.map((item) => {
      const symptomsName = item[localizeNameKey];

      return {
        value: item.id,
        label: symptomsName as string,
      };
    });
    return options;
  }, [symptoms, localizeNameKey]);

  const getSymptomsData = () => {
    dispatch(
      getSymptoms({
        page: currentPage,
        limit: 10,
        languageIso: language,
        search: searchItem,
      }),
    ).then((data) => {
      if (data.meta.requestStatus === 'fulfilled') {
        const dataArr =
          data.payload as CommonEntitiesListResponse<SymptomResponseDto>;

        dispatch(
          setSymptoms(
            currentPage === 1
              ? dataArr?.entities!
              : [...symptoms, ...dataArr.entities!],
          ),
        );

        if (!dataArr?.entities.length) {
          setHasNextPage(!dataArr?.entities.length);
        }
      }
    });
  };

  useEffect(() => {
    if (currentPage <= symptomsTotalPages || symptomsTotalPages === 0) {
      getSymptomsData();
    }
  }, [currentPage, searchItem]);

  const handleGetNewData = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const handleSearchData = (search: string) => {
    setSearchItem(search);
    setHasNextPage(true);
    setCurrentPage(1);
  };

  const { register, errors, handleSubmit, getValues, setValue, watch } =
    useConsultationDataForm({
      minValue: t('consultation_data.errors.minValue'),
      maxValue: t('consultation_data.errors.maxValue'),
      integer: t('errors.integer'),
    });

  const onSubmit = () => {
    const {
      reason,
      symptoms,
      respiratoryRate,
      spO2,
      pulse,
      bloodPressureSystolic,
      bloodPressureDiastolic,
      temperature,
      bloodGlucose,
      weight,
      height,
    } = getValues();

    dispatch(
      saveConsultationData({
        appointmentId: appointmentId!,
        data: {
          ...(reason?.length ? { reason } : {}),
          symptomIds: symptoms?.length ? symptoms : [],
          ...(notes?.length ? { notes } : {}),
          ...(plan?.length ? { treatmentPlan: plan } : {}),
          healthMetrics: {
            ...(respiratoryRate ? { respiratoryRate: +respiratoryRate } : {}),
            ...(spO2 ? { spO2: +spO2 } : {}),
            ...(pulse ? { pulse: +pulse } : {}),
            ...(bloodPressureSystolic
              ? { bloodPressureSystolic: +bloodPressureSystolic }
              : {}),
            ...(bloodPressureDiastolic
              ? { bloodPressureDiastolic: +bloodPressureDiastolic }
              : {}),
            ...(temperature ? { temperature: +temperature } : {}),
            ...(bloodGlucose ? { bloodGlucose: +bloodGlucose } : {}),
            ...(weight ? { weight: +weight } : {}),
            ...(height ? { height: +height } : {}),
          },
        },
      }),
    ).then((result) => {
      if (result.meta.requestStatus === 'fulfilled') {
        setSuccessModalOpened(true);
      }
    });
  };

  return (
    <Wrapper>
      {isLoading && <Loader />}
      <DataWrapper>
        <DataSection>
          <Information>
            <h3>{t('consultation_data.consultation_information')}</h3>
            <Input
              register={register}
              type={'text'}
              id={'reason'}
              label={t('consultation_data.reasons_for_consultation') || ''}
              placeholder={t('consultation_data.reason_placeholder') || ''}
            />
            <InfinityScrollDropdown
              id={'symptoms'}
              label={t('consultation_data.symptoms') || ''}
              placeholder={t('consultation_data.select_symptoms')}
              onChange={(value) => {
                setValue('symptoms', value as string[], {
                  shouldValidate: true,
                });
              }}
              value={(watch('symptoms') as string[]) || []}
              options={symptomsOptions}
              isMulti
              withSearch
              getNewData={handleGetNewData}
              hasNextPage={hasNextPage}
              onSearchData={handleSearchData}
            />
          </Information>
          <Metrics>
            <h3>{t('consultation_data.health_metrics')}</h3>
            <MetricsWrapper>
              <div>
                <MetricItem>
                  <Input
                    id={'respiratoryRate'}
                    register={register}
                    type={'number'}
                    label={t('consultation_data.respiratory_rate') || ''}
                    errorMessage={errors.respiratoryRate?.message}
                  />
                  <span>{t('metrics.bpm')}</span>
                </MetricItem>
                <MetricItem>
                  <Input
                    id={'pulse'}
                    register={register}
                    type={'number'}
                    label={t('consultation_data.pulse') || ''}
                    errorMessage={errors.pulse?.message}
                  />
                  <span>{t('metrics.bpm')}</span>
                </MetricItem>
                <MetricItem>
                  <Input
                    id={'temperature'}
                    register={register}
                    type={'number'}
                    label={t('consultation_data.temperature') || ''}
                    errorMessage={errors.temperature?.message}
                  />
                  <span>&deg;C</span>
                </MetricItem>
                <MetricItem>
                  <Input
                    id={'weight'}
                    register={register}
                    type={'number'}
                    label={t('consultation_data.weight') || ''}
                    errorMessage={errors.weight?.message}
                  />
                  <span>{t('metrics.kg')}</span>
                </MetricItem>
              </div>
              <div>
                <MetricItem>
                  <Input
                    id={'spO2'}
                    register={register}
                    type={'number'}
                    label={t('consultation_data.SpO2') || ''}
                    errorMessage={errors.spO2?.message}
                  />
                  <span>%</span>
                </MetricItem>
                <MetricItem
                  className={'blood_pressure'}
                  isError={
                    !!errors.bloodPressureSystolic?.message ||
                    !!errors.bloodPressureDiastolic?.message
                  }
                >
                  <label>{t('consultation_data.blood_pressure')}</label>
                  <div>
                    <Input
                      id={'bloodPressureSystolic'}
                      register={register}
                      type={'number'}
                      label={''}
                      errorMessage={errors.bloodPressureSystolic?.message}
                    />
                    <span>/</span>
                    <Input
                      id={'bloodPressureDiastolic'}
                      register={register}
                      type={'number'}
                      label={''}
                      errorMessage={errors.bloodPressureDiastolic?.message}
                    />
                  </div>
                </MetricItem>
                <MetricItem>
                  <Input
                    id={'bloodGlucose'}
                    register={register}
                    type={'number'}
                    label={t('consultation_data.blood_glucose') || ''}
                    errorMessage={errors.bloodGlucose?.message}
                  />
                  <span>{t('consultation_data.mmol')}</span>
                </MetricItem>
                <MetricItem>
                  <Input
                    id={'height'}
                    register={register}
                    type={'number'}
                    label={t('consultation_data.hight') || ''}
                    errorMessage={errors.height?.message}
                  />
                  <span>cm</span>
                </MetricItem>
              </div>
            </MetricsWrapper>
          </Metrics>
        </DataSection>
        <EditorsSection>
          <EditorWrapper>
            <h3>{t('consultation_data.notes')}</h3>
            <Editor
              editorKey={'notes'}
              value={data?.notes || ''}
              onChange={(val) => {
                setNotes(val);
              }}
              hasError={false}
            />
          </EditorWrapper>
          <EditorWrapper>
            <h3>{t('consultation_data.treatment_plan')}</h3>
            <Editor
              editorKey={'plan'}
              value={data?.treatmentPlan || ''}
              onChange={(val) => {
                setPlan(val);
              }}
              hasError={false}
            />
          </EditorWrapper>
        </EditorsSection>

        <ButtonWrapper>
          <SecondaryButton onClick={() => {}}>{t('cancel')}</SecondaryButton>
          <PrimaryButton onClick={handleSubmit(onSubmit)}>
            {t('save')}
          </PrimaryButton>
        </ButtonWrapper>
      </DataWrapper>
      <SuccessModal
        isOpen={successModalOpened}
        onClose={() => {
          dispatch(getConsultationData(appointmentId!));
          setSuccessModalOpened(false);
        }}
        message={t('consultation_data.success_modal.message') || ''}
        closeButtonText={t('consultation_data.success_modal.buttonText') || ''}
      />
    </Wrapper>
  );
};

export default ConsultationData;
