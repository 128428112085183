import { createSlice } from '@reduxjs/toolkit';
import {
  getConsultationData,
  getConsultationDataHistory,
  saveConsultationData,
  getPatientMedicalBackground,
  removePatientAllergy,
  removePatientDisease,
  setPatientFamilyHistoricData,
  deletePatientFamilyHistoricData,
  deleteSurgeryData,
  getSymptoms,
  getSurgeries,
  getDiseases,
  getAllergies,
} from './ActionCreators';
import { ConsultationDataState } from './models';

const initialState: ConsultationDataState = {
  data: null,
  history: [],
  patientMedicalBackground: null,
  allergies: [],
  diseases: [],
  surgeries: [],
  symptoms: [],
  isLoading: false,
  surgeriesIsLoading: false,
  error: '',
  symptomsTotalPages: 1,
  surgeriesTotalPages: 1,
  diseasesTotalPages: 1,
  allergiesTotalPages: 1,
};

const consultationSlice = createSlice({
  name: 'consultationSlice',
  initialState,
  reducers: {
    setSymptoms: (state, action) => {
      state.symptoms = action.payload;
    },
    setAllergies: (state, action) => {
      state.allergies = action.payload;
    },
    setDiseases: (state, action) => {
      state.diseases = action.payload;
    },
    setSurgeries: (state, action) => {
      state.surgeries = action.payload;
    },
  },
  extraReducers: (builder) => {
    // get consultation data
    builder.addCase(getConsultationData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getConsultationData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getConsultationData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    // put consultation data
    builder.addCase(saveConsultationData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(saveConsultationData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(saveConsultationData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    // put consultation data
    builder.addCase(getConsultationDataHistory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getConsultationDataHistory.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getConsultationDataHistory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.history = action.payload;
    });
    // put consultation data
    builder.addCase(getPatientMedicalBackground.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPatientMedicalBackground.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getPatientMedicalBackground.fulfilled, (state, action) => {
      state.isLoading = false;
      state.patientMedicalBackground = action.payload;
    });
    // remove allergy
    builder.addCase(removePatientAllergy.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(removePatientAllergy.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(removePatientAllergy.fulfilled, (state) => {
      state.isLoading = false;
    });
    // remove disease
    builder.addCase(removePatientDisease.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(removePatientDisease.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(removePatientDisease.fulfilled, (state) => {
      state.isLoading = false;
    });
    // set family history
    builder.addCase(setPatientFamilyHistoricData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(setPatientFamilyHistoricData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(setPatientFamilyHistoricData.fulfilled, (state) => {
      state.isLoading = false;
    });
    // delete family history
    builder.addCase(deletePatientFamilyHistoricData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      deletePatientFamilyHistoricData.rejected,
      (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      },
    );
    builder.addCase(
      deletePatientFamilyHistoricData.fulfilled,
      (state, action) => {
        state.isLoading = false;
      },
    );
    // delete surgery
    builder.addCase(deleteSurgeryData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteSurgeryData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(deleteSurgeryData.fulfilled, (state) => {
      state.isLoading = false;
    });
    // get symptoms
    builder.addCase(getSymptoms.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSymptoms.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getSymptoms.fulfilled, (state, action) => {
      state.isLoading = false;
      state.symptomsTotalPages = action.payload.totalPages!;
    });
    // get surgeries
    builder.addCase(getSurgeries.pending, (state) => {
      state.surgeriesIsLoading = true;
    });
    builder.addCase(getSurgeries.rejected, (state, action) => {
      state.surgeriesIsLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getSurgeries.fulfilled, (state, action) => {
      state.surgeriesIsLoading = false;
      state.surgeriesTotalPages = action.payload.totalPages!;
    });
    // get diseases
    builder.addCase(getDiseases.pending, (state) => {
      state.surgeriesIsLoading = true;
    });
    builder.addCase(getDiseases.rejected, (state, action) => {
      state.surgeriesIsLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getDiseases.fulfilled, (state, action) => {
      state.surgeriesIsLoading = false;
      state.diseasesTotalPages = action.payload.totalPages!;
    });
    // get allergies
    builder.addCase(getAllergies.pending, (state) => {
      state.surgeriesIsLoading = true;
    });
    builder.addCase(getAllergies.rejected, (state, action) => {
      state.surgeriesIsLoading = false;
      state.error = action.payload as string;
    });
    builder.addCase(getAllergies.fulfilled, (state, action) => {
      state.surgeriesIsLoading = false;
      state.allergiesTotalPages = action.payload.totalPages!;
    });
  },
});

export const { setSymptoms, setAllergies, setDiseases, setSurgeries } =
  consultationSlice.actions;

export default consultationSlice.reducer;
