import React, { FC } from 'react';
import Markdown from 'react-markdown';
import gfm from 'remark-gfm';

import { MarkdownTextProps } from './models';
import { Wrapper } from './styles';

const MarkdownText: FC<MarkdownTextProps> = ({ text }) => {
  return (
    <Wrapper>
      <Markdown remarkPlugins={[gfm]}>{text}</Markdown>
    </Wrapper>
  );
};

export default MarkdownText;
